export default {
  'conte': {
    title: 'Les cris des animaux',
    path: '01-conte',
    files: [
      { file: 'le-conte-lu-avec-habillage', name: 'Le conte lu avec habillage', },
      { file: 'le-conte-raconte', name: 'Le conte raconté', },
    ],
  },
  'les_cris_des_animaux': {
    title: 'Les cris des animaux',
    path: '04-les_sons_de_la_montagne/les_cris_des_animaux',
    files: [
      { file: 'la_chevre', name: 'La chèvre', },
      { file: 'la_vache', name: 'La vache', },
      { file: 'la-marmotte', name: 'La marmotte', },
      { file: 'le_bouquetin', name: 'Le bouquetin', },
      { file: 'le_brame_du_cerf', name: 'Le brame du cerf', },
      { file: 'le_chamois', name: 'Le chamois', },
      { file: 'le_chien', name: 'Le chien', },
      { file: 'le_coucou', name: 'Le coucou', },
      { file: 'le_gypaete_barbu', name: 'Le gypaète barbu', },
      { file: 'le_mouton', name: 'Le mouton', },
      { file: 'le_tetras-lyre', name: 'Le tétras-lyre', },
      { file: 'montee_en_alpages_sons_de_cloches_et_cris_des_bergers', name: 'Montée en alpage, sons de cloches et cris des bergers', },
      { file: 'son_de_cloches_en_alpage_troupeau_de_vache', name: 'Sons de cloches en alpage, troupeau de vaches ', },
      { file: 'sons_de_cloches_en_alpage_avec_meuglement_de_vaches_et_appel_du_berger', name: 'Sons de cloches en alpage avec meuglement de vaches et appel du berger', },
      { file: 'sons_de_cloches_en_alpage_avec_meuglement_de_vaches', name: 'Sons de cloches en alpage avec meuglement de vaches', },
      { file: 'sons_de_cloches_en_alpage_et_aboiement_de_chien', name: 'Sons de cloches en alpage et aboiement de chien', },
    ],
  },
  'les_sons_de_la_nature': {
    title: 'Les sons de la nature',
    path: '04-les_sons_de_la_montagne/les_sons-de_la_nature',
    files: [
      { file: 'le_vent_dans_les-herbes', name: 'Le vent dans les herbes', },
      { file: 'un_coup_de_tonnerrre', name: 'Un coup de tonnerre', },
      { file: 'un_filet_d-eau_qui_coule_dans_un_bassin', name: 'Un filet d’eau qui coule dans un bassin', },
      { file: 'un_orage', name: 'Un orage', },
      { file: 'un_petit_ruisseau_tranquille', name: 'Un petit ruisseau tranquille', },
      { file: 'un_ruisseau_rapide', name: 'Un ruisseau rapide', },
      { file: 'une_cascade_en_montagne', name: 'Une cascade en montagne', },
      { file: 'une_riviere', name: 'Une rivière', },
    ],
  },
  'chansons': {
    title: 'Les chansons',
    path: '02-chansons',
    files: [
      { file: 'alpagistes', name: 'Alpagistes', },
      { file: 'cloches', name: 'Cloches', },
      { file: 'herbe_tendre', name: 'Herbe tendre', },
      { file: 'il_neige_sur_les_pres', name: 'Il neige sur les prés', },
      { file: 'l-echo', name: 'L’écho', },
      { file: 'la_marmotte', name: 'La marmotte', },
      { file: 'la_source_du_grand_pre', name: 'La source du grand pré', },
      { file: 'le_petit_sentier', name: 'Le petit sentier', },
      { file: 'le_vieux_sapin', name: 'Le vieux sapin', },
      { file: 'les_secrets_de_la_foret', name: 'Les secrets de la forêt', },
      { file: 'pour_faire_un_bon_fromage', name: 'Pour faire un bon fromage !', },
      { file: 'retour_des_alpages', name: 'Retour des alpages', },
      { file: 'tetras-lyre', name: 'Tétras-lyre', },
      { file: 'vaches_chevres_et_moutons', name: 'Vaches, chèvres et moutons', },
    ],
  },
  'bandes_sons_des_chansons': {
    title: 'Les bandes-son des chansons',
    path: '03-bandes_sons_des_chansons',
    files: [
      { file: 'alpagistes_pb', name: 'Alpagistes', },
      { file: 'cloches_pb', name: 'Cloches', },
      { file: 'herbe_tendre_pb', name: 'Herbe tendre', },
      { file: 'il_neige_sur_les_pres_pb', name: 'Il neige sur les prés', },
      { file: 'l-echo_pb', name: 'L’écho', },
      { file: 'la_marmotte_pb', name: 'La marmotte', },
      { file: 'la_ronde_du_p-tit_berger', name: 'La ronde du p’tit berger', },
      { file: 'la_source_du_grand_pre_pb', name: 'La source du grand pré', },
      { file: 'le_petit_sentier_pb', name: 'Le petit sentier', },
      { file: 'le_vieux_sapin_pb', name: 'Le vieux sapin', },
      { file: 'les_secrets_de_la_foret_pb', name: 'Les secrets de la forêt', },
      { file: 'pour_faire_un_bon_fromage_pb', name: 'Pour faire un bon fromage !', },
      { file: 'retour_des_alpages_pb', name: 'Retour des alpages', },
      { file: 'tetras-lyre_pb', name: 'Tétras-lyre', },
      { file: 'vaches_chevres_et_moutons_pb', name: 'Vaches, chèvres et moutons', },
    ],
  },
}
