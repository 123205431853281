import React, { Fragment, useState } from 'react';
import { AudioPlayerProvider, useAudioPlayer, useAudioPosition } from 'react-use-audio-player';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Cookies from 'js-cookie';

import PDFLink from '../components/PDFLink';
import AudioLinkGroup from '../components/AudioLinkGroup';
import ModalError from '../components/ModalError';

import {AudioContext} from '../constants/audio-context';
import {AudioGroupContext} from '../constants/audio-group-context';
import GLOBAL_MEDIA_QUERIES from '../constants/media-queries';

function AudioContent() {
  const useCookieSave = false;
  const [openedDescription, openDescription] = useState(null);
  const handleOpenDescription = slug => openDescription(slug === openedDescription ? null : slug);
  const [audioPlayingState, setAudioPlaying] = useState(null);
  const [audioErrorModalIsOpen, setAudioErrorModalIsOpen] = useState(false);
  const [alreadyPlayed, setAlreadyPlayed] = useState(useCookieSave && Array.isArray(JSON.parse(Cookies.get('audiosAlreadyPlayed'))) ? JSON.parse(Cookies.get('audiosAlreadyPlayed')) : []);
  const [audioProgressStates, setAudioProgressStates] = useState({});
  const [audioGroupOpenState, setAudioGroup] = useState(null);
  const { load, play, stop, pause, ready, loading, playing, error } = useAudioPlayer();
  const { percentComplete } = useAudioPosition({ highRefreshRate: true });
  const toggleAudio = file => {
    // console.log(file, '-----------', audioPlayingState, '--------------', ready, loading, playing, error);
    if (audioPlayingState === file) {
      if (playing) {
        pause();
      } else {
        play();
      }
    } else {
      stop(audioPlayingState);
      load({
        src: `/mp3/${file.replace('.mp3', '')}.mp3`,
        format: 'mp3',
        autoplay: true,
        onload: () => {
          play(file);
          audioProgressStates[file] = 0;
          setAudioProgressStates(audioProgressStates);
        },
        onloaderror: () => {
          setAudioErrorModalIsOpen(true);
        },
        onplayerror: () => {
          setAudioErrorModalIsOpen(true);
        },
        onend: () => {
          if (!alreadyPlayed.includes(file)) {
            alreadyPlayed.push(file);
            setAlreadyPlayed(alreadyPlayed);
            Cookies.set('audiosAlreadyPlayed', JSON.stringify(alreadyPlayed));
          }
          setAudioPlaying(null);
        },
      });
      setAudioPlaying(file);
    }
  };
  return <AudioContext.Provider value={{
    audioPlaying: audioPlayingState,
    alreadyPlayed,
    error,
    ready,
    loading,
    playing,
    percentComplete,
    toggle: toggleAudio,
  }}>
    <AudioGroupContext.Provider value={{
      audioGroupOpen: audioGroupOpenState,
      toggle: group => setAudioGroup(audioGroupOpenState === group ? null : group),
    }}>
      <div className="grid">
        <div className="column-half">
          <h2>Le conte</h2>
          <div className="btn-group">
            <AudioLinkGroup audiosGroup="conte/le-conte-lu-avec-habillage" />
            <AudioLinkGroup audiosGroup="conte/le-conte-raconte" />
          </div>
          <h2>Les sons de la montagne</h2>
          <div className="btn-group">
            <AudioLinkGroup audiosGroup="les_cris_des_animaux" />
            <AudioLinkGroup audiosGroup="les_sons_de_la_nature" />
          </div>
        </div>
        <div className="column-half">
          <h2>Les chansons</h2>
          <div className="btn-group">
            <AudioLinkGroup audiosGroup="chansons" />
            <AudioLinkGroup audiosGroup="bandes_sons_des_chansons" />
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="chansons_partitions" title="Les partitions des chansons" />
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="fiches_pedagogiques_chansons" title="Les fiches pédagogiques des chansons" />
          </div>
        </div>
      </div>
      <ModalError isOpen={audioErrorModalIsOpen} setIsOpen={setAudioErrorModalIsOpen} title="audio" />
    </AudioGroupContext.Provider>
  </AudioContext.Provider>
}

export default function Audio({ data }) {
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.tabletImage.childImageSharp.fluid,
      media: GLOBAL_MEDIA_QUERIES.tablet,
    },
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: GLOBAL_MEDIA_QUERIES.desktop,
    },
  ];
  return <Fragment>
    <div className="container">
      <h1>Les contenus audios</h1>
      <AudioPlayerProvider>
        <AudioContent />
      </AudioPlayerProvider>
      <img src="/images/marmotte2.png" className="perso-footer perso-footer-marmotte2" aria-hidden={true} alt="" />
    </div>
    <div className="image-bg"><Img fluid={sources} /></div>
  </Fragment>
}

export const query = graphql`
query {
  mobileImage: file(relativePath: { eq: "audios-bg.png" }) {
    childImageSharp {
      fluid(maxWidth: 354, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
  tabletImage: file(relativePath: { eq: "audios-bg.png" }) {
    childImageSharp {
      fluid(maxWidth: 668, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
  desktopImage: file(relativePath: { eq: "audios-bg.png" }) {
    childImageSharp {
      fluid(maxWidth: 788, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
}
`;
