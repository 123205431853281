import React from 'react';
export const AudioContext = React.createContext( {
  audioPlaying: null,
  error: null,
  ready: null,
  loading: null,
  playing: null,
  percentComplete: 0,
  toggle: () => {},
} );
