import React, { Fragment } from 'react';

import { TiPlus as IconPlus, TiMinus as IconMinus } from 'react-icons/ti';

import {AudioGroupContext} from '../constants/audio-group-context';

import AudioLink from './AudioLink';

import audios from '../constants/audios';

import IconAudio from './shapes/icon-audios.svg';

export default function AudioLinkGroup({ audiosGroup }) {
  const group = audiosGroup.split('/');
  const { files, title, path } = audios[group[0]];
  const filesFiltered = group[1] ? files.filter(file => file.file === group[1]) : files;
  return <Fragment>
    {filesFiltered.length === 1 ? <AudioLink audio={`${path}/${filesFiltered[0].file}`}>{ filesFiltered[0].name }</AudioLink> : <AudioGroupContext.Consumer>
      {({audioGroupOpen, toggle}) => (
        <div className="subgroup">
          <button className="audio-group-trigger" onClick={() => toggle(audiosGroup)}><IconAudio />{ title }{ audiosGroup === audioGroupOpen ? <IconMinus className="marker-right" /> : <IconPlus className="marker-right" /> }</button>
          <div className={`audio-group-container ${audiosGroup === audioGroupOpen ? 'open' : ''}`}>
            { filesFiltered.map((audio, index) => (
              <AudioLink key={index} audio={`${path}/${audio.file}`}>{audio.name}</AudioLink>
            )) }
          </div>
        </div>
      )}
    </AudioGroupContext.Consumer>}
  </Fragment>
}
