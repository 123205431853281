import React from 'react';
import { FaSpinner as IconSpinner, FaPlay as IconPlay, FaPause as IconPause, FaTimes as IconError } from 'react-icons/fa';

import DownloadLink from './DownloadLink';

import {AudioContext} from '../constants/audio-context';

import IconAudio from './shapes/icon-audios.svg';
import IconCheck from './shapes/icon-check.svg';

export default function AudioLink({ audio, icon, children, title, className, ...props }) {
  const normalIcon = !icon ? <IconAudio /> : icon;
  const toClean = audio.replace('.mp3', '').replace('/', '-');
  
  return <AudioContext.Consumer>
    {({audioPlaying, error, loading, playing, percentComplete, alreadyPlayed, toggle}) => (
      <div className="link with-download-link">
        <button className={`link-audio link-audio--${toClean}${className ? ` ${className}` : ''}${audioPlaying === audio ? `${error ? ' error' : ''}${loading ? ' loading' : ''}${playing ? ' playing' : (!loading ? ' paused' : '')}` : ''}${alreadyPlayed.includes(audio) ? ' played' : ''}`} onClick={() => toggle(audio)} {...props}>
          {audioPlaying === audio ? (
              error ? <IconError /> : (
                playing ? <IconPause /> : (
                  loading ? <IconSpinner /> : 
                    <IconPlay />
                )
              )
            ) : (
            alreadyPlayed.includes(audio) ? <IconCheck />
            : normalIcon
          )}
          <span className="label">{ children }</span>
          <span className="progress" style={{ width: `${alreadyPlayed.includes(audio) && !playing ? 100 : (audioPlaying === audio ? percentComplete : 0)}%` }}></span>
        </button>
        <DownloadLink url={`/mp3/${audio.replace('.mp3', '')}.mp3`} slug={toClean} ext="mp3" title={`Télécharger le fichier son ${title}`} />
      </div>
    )}
  </AudioContext.Consumer>
}
